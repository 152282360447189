var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.authUserPermission['duplicate-view'])?_c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4 mt--7"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('check_duplicate_by_part_number')))])]),_c('div',{staticClass:"col-8 text-right"},[(_vm.authUserPermission['duplicate-by-part-number-export'])?_c('a',{staticClass:"btn btn-sm btn-outline-dark",attrs:{"href":_vm.apiUrl+'CheckDuplicateByPartNumber/export?token='+_vm.token}},[_vm._v("Export as Excel")]):_vm._e(),_c('input',{staticClass:"day_month_year",attrs:{"type":"hidden","value":""}}),_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('select',{staticClass:"btn btn-outline-dark btn-sm",attrs:{"name":"day_month_year"},on:{"change":_vm.changeFilter}},[_c('option',{attrs:{"value":"2"}},[_vm._v("By Part Number")]),_c('option',{attrs:{"value":"1"}},[_vm._v("By Description")])])])])])]),_c('div',[(!_vm.onLoad)?_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.data,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":_vm.tt('material_number'),"prop":_vm.tt('material_number'),"min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.material_number)+" ")]}}],null,false,703614824)}),_c('el-table-column',{attrs:{"label":_vm.tt('item_name'),"prop":_vm.tt('item_name'),"min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item_name)+" ")]}}],null,false,1570853394)}),_c('el-table-column',{attrs:{"label":_vm.tt('short_description'),"prop":_vm.tt('short_description'),"min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.short_description)+" ")]}}],null,false,3648395188)}),_c('el-table-column',{attrs:{"label":_vm.tt('material_type'),"prop":_vm.tt('material_type'),"min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.material_type)+" ")]}}],null,false,3768046963)}),_c('el-table-column',{attrs:{"label":_vm.tt('material_group'),"prop":_vm.tt('material_group'),"min-width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.material_group)+" ")]}}],null,false,1341982260)}),_c('el-table-column',{attrs:{"label":_vm.tt('uom'),"prop":_vm.tt('uom'),"min-width":"130","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.uom)+" ")]}}],null,false,3205574152)}),_c('el-table-column',{attrs:{"label":_vm.tt('mfr_code'),"prop":_vm.tt('mfr_code'),"min-width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.manufacture_code)+" ")]}}],null,false,498069640)}),_c('el-table-column',{attrs:{"label":_vm.tt('mfr_ref'),"prop":_vm.tt('mfr_ref'),"min-width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.manufacture_ref)+" ")]}}],null,false,1148077684)}),_c('el-table-column',{attrs:{"label":_vm.tt('cat_status'),"prop":_vm.tt('cat_status'),"min-width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.catalog_status)+" ")]}}],null,false,494416487)})],1):_c('page-loading')],1),_c('div',{staticClass:"card-footer pb-0 "},[_c('span',{staticClass:"float-left"},[_c('base-pagination',{attrs:{"page-count":_vm.table.page.last_page,"size":"sm"},nativeOn:{"click":function($event){return _vm.changePage(_vm.table.page.current_page)}},model:{value:(_vm.table.page.current_page),callback:function ($$v) {_vm.$set(_vm.table.page, "current_page", $$v)},expression:"table.page.current_page"}})],1),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.tt('page_info', {size: (_vm.table.page.current_page - 1) * _vm.table.page.per_page + _vm.table.data.length, total: _vm.table.total}))+" ")])])])])],1):_c('noaccess')],1)}
var staticRenderFns = []

export { render, staticRenderFns }